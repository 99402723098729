import React from "react";
import { FormattedMessage } from "react-intl";
import DecodedButton from "../../../../common/DecodedButton";
import { getInLineUtil } from "../../../../../utils/getInLineUtils";
import { TextMediumStandard } from "../../../../shared/Typography";
import { dateUtil } from "../../../../../utils/date";

const GetInLineAvailability = (props) => {
  const {
    isWithinOperatingHours,
    handleGetInLine,
    estimatedWaitTime,
    walkinSlot,
  } = props;

  const slotsAvailable = walkinSlot === "CURRENTLY AVAILABLE";

  const waitWithinOperatingHours = () =>
    estimatedWaitTime !== 0 ? (
      <TextMediumStandard
        sx={{
          fontStyle: "normal",
          fontWeight: "bold",
          lineHeight: "24px",
        }}
      >
        ESTIMATED WAIT TIME: &nbsp;
        <span style={{ color: "#2D8088", fontSize: ".85em" }}>
          {walkinSlot === "CURRENTLY AVAILABLE"
            ? dateUtil.formatWaitTimeDisplay(estimatedWaitTime)
            : "Currently Unavailable."}
        </span>
      </TextMediumStandard>
    ) : (
      <TextMediumStandard
        sx={{
          fontStyle: "normal",
          fontWeight: "bold",
          lineHeight: "24px",
        }}
        color="primary"
      >
        THERE IS CURRENTLY NO WAIT TO BE SEEN.
      </TextMediumStandard>
    );

  return (
    <>
      {isWithinOperatingHours && slotsAvailable && waitWithinOperatingHours()}
      <FormattedMessage
        id={
          isWithinOperatingHours && slotsAvailable
            ? "landing.get_in_line"
            : "landing.get_in_line.start"
        }
        values={{
          lineBreak: <br />,
          startTime: getInLineUtil.startTimeDisplay(),
        }}
        defaultMessage={
          isWithinOperatingHours && slotsAvailable
            ? `GET IN LINE NOW...{lineBreak}WAIT WHERE YOU WANT`
            : `Virtual line will be available at {startTime}`
        }
      >
        {(txt) => (
          <DecodedButton
            variant="contained"
            onClick={handleGetInLine}
            children={txt}
            disabled={!isWithinOperatingHours || !slotsAvailable}
          />
        )}
      </FormattedMessage>
    </>
  );
};

export default GetInLineAvailability;
