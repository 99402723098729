import { logger } from './logging';

export function retryOn502(fn, retries = 3, delay = 500) {
  return fn()
    .then((data) => data)
    .catch((err) => {
      if (err.response.status !== 502 || retries === 0) {
        throw new Error(err.response?.data?.message || err, { cause: err });
      }

      logger.debug('retrying...');
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          retryOn502(fn, retries - 1, delay)
            .then(resolve)
            .catch(reject);
        }, delay);
      });
    });
}

export default function retry(fn, retries = 3, delay = 500) {
  return fn()
    .then((data) => data)
    .catch((err) => {
      if (retries === 0) {
        throw new Error(err);
      }
      logger.debug('retrying...');
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          retry(fn, retries - 1, delay)
            .then(resolve)
            .catch(reject);
        }, delay);
      });
    });
}
