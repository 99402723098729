import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import { H1TextStepTitle, SmallButtonText } from "../../../shared/Typography";
import DecodedTextField from "../../../../utils/DecodedTextFIeld";
import { authService } from "../../../../utils/auth";
import { ROOT_ROUTE } from "../../../../utils/route.name";
import { AnalyticsEvent, analyticsEventLogger } from "../../../../utils/events";
import { LoginDataContext } from "../LoginDataProvider";
import { phoneUtil } from "../../../../utils/phone";
import { userInfoUtil } from "../../../../utils/user";
import { useMediaQueries } from "../../../../utils/hooks/useMediaQueries";
import { TextButton } from "../../../shared/components/TextButton";
import { FormattedMessage } from "react-intl";
import ScrollableContainer from "../../../common/ScrollableContainer";
import PageContainer from "../../../common/PageContainer";
import { StyledPinCodeGrid } from "./StyledGrid";
import { Loading } from "../../../../shared-library";

const PinCode = (props) => {
  const { code, codeCountry } = props;

  const [pinError, setPinError] = useState(false);
  const [loadingOTP, setLoadingOTP] = useState(false);

  const { loginData, setLoginData } = useContext(LoginDataContext);

  const mediaQuery = useMediaQueries();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setLoginData((prevState) => ({ ...prevState, [name]: value }));

    if (pinError) {
      setPinError(false);
    }

    if (value.length === 6) {
      doPinSubmit(value);
    }
  };

  const doPinSubmit = (data) => {
    setLoadingOTP(true);

    authService
      .confirmVerificationCode(createAuthRequest(data))
      .then((token) => {
        authService
          .loginWithToken(token)
          .then((user) => {
            analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_SUCCESS);

            setLoginData((prevData) => ({
              ...prevData,
              verificationSuccess: true,
            }));

            analyticsEventLogger.setUser(user.uid);
          })
          .catch((error) => {
            setLoginData((prevData) => ({ ...prevData, pin: "" }));
            setPinError(true);
            setLoadingOTP(false);
            analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_ERROR, {
              reason: `${error}`,
            });
          });
      })
      .catch((error) => {
        setLoginData((prevData) => ({ ...prevData, pin: "" }));
        setPinError(true);
        setLoadingOTP(false);
        analyticsEventLogger.log(AnalyticsEvent.AUTH_OTP_ERROR, {
          reason: `${error}`,
        });
      })
      .finally(() => {
        sessionStorage.removeItem("currentPath");
      });
  };

  const createAuthRequest = (data) => {
    return {
      pin: data,
      name: {
        given: loginData.firstName,
        family: loginData.lastName,
      },
      dob: userInfoUtil.formatDate(loginData.dateOfBirth),
      number: loginData.number
        ? phoneUtil.formatPhoneNumberForRegistration(
            loginData.number,
            props.code,
            props.codeCountry
          )
        : null,
      email: loginData.email,
    };
  };

  if (loginData.verificationSuccess) {
    return (
      <Redirect
        to={{
          pathname: ROOT_ROUTE,
          state: {
            loginData,
            code,
            codeCountry,
            addressCountry: codeCountry,
          },
        }}
      />
    );
  }

  return (
    <PageContainer>
      <ScrollableContainer>
        <Grid container sx={{ padding: mediaQuery.isSm ? "20px" : "80px" }}>
          <Grid item>
            <FormattedMessage
              id="login.patient.otp"
              defaultMessage="Please enter the pin code texted to the mobile number provided"
            >
              {(txt) => (
                <H1TextStepTitle
                  isMobile={mediaQuery.isMobileLandscape || mediaQuery.isSm}
                >
                  {txt}
                </H1TextStepTitle>
              )}
            </FormattedMessage>
          </Grid>
          <Grid
            container
            sx={{
              width: "75%",
              "@media (max-width:600px)": {
                width: "100%",
              },
              display: mediaQuery.isMobileLandscape && "flex",
              flexDirection: mediaQuery.isMobileLandscape && "row",
              justifyContent: mediaQuery.isMobileLandscape && "flex-start",
              alignItems: mediaQuery.isMobileLandscape && "flex-end",
              flexWrap: mediaQuery.isMobileLandscape && "nowrap",
            }}
          >
            <StyledPinCodeGrid item xs={12} sm={6} md={6} my="50px">
              <DecodedTextField
                autoFocus
                fullWidth
                id="pin"
                name="pin"
                type="tel"
                label={
                  <FormattedMessage
                    id="login.pin.field.label.pin"
                    defaultMessage={"Six Digit Pin Code"}
                  />
                }
                placeholder="_ _ _ _ _ _"
                value={loginData.pin}
                onChange={handleInputChange}
                variant="standard"
                inputProps={{
                  style: { fontSize: "2em", textAlign: "center" },
                  pattern:"[0-9]*",
                  inputMode: "numeric",
                  autoComplete: "one-time-code"
                }}
                error={pinError}
                helperText={pinError && "Invalid pin code"}
                disabled={loadingOTP}
              />
              {loadingOTP ? (
                <Box mt={1}>
                  <Loading message="Confirming pin code" primary />
                </Box>
              ) : (
                <TextButton>
                  <SmallButtonText
                    onClick={() =>
                      props.setIndex((prevIndex) => (prevIndex - 1) % 7)
                    }
                  >
                    Resend pin code?
                  </SmallButtonText>
                </TextButton>
              )}
            </StyledPinCodeGrid>
          </Grid>
        </Grid>
      </ScrollableContainer>
    </PageContainer>
  );
};

export default PinCode;
