import React from "react";
import { Grid } from "@mui/material";
import { TextMediumStandard } from "../../../../shared/Typography";

const WalkInAvailabilityWrapper = ({ children }) => (
  <Grid container id="walk.in.availability">
    {children}
  </Grid>
);

const WalkInAvailability = (props) => {
  const { walkinSlot } = props;

  return (
    <WalkInAvailabilityWrapper>
      <Grid item xs={12}>
        <TextMediumStandard
          sx={{
            fontStyle: "normal",
            fontWeight: "bold",
            lineHeight: "24px",
            color: walkinSlot === "UNAVAILABLE" ? "grey" : "#2D8088",
          }}
        >
          WALK-IN: {walkinSlot || "Loading"}
        </TextMediumStandard>
      </Grid>
    </WalkInAvailabilityWrapper>
  );
};

export default WalkInAvailability;
