class GetInLineUtil {
  constructor() {
    this.locationsString = process.env.REACT_APP_GET_IN_LINE_ENABLED_LOCATIONS;
  }

  /**
   * Converts a valid four-digit time string (HHMM) into a Date object.
   *
   * This function validates the input string to ensure it is exactly four numeric characters,
   * parses the hours and minutes, and sets these values on a new Date object.
   *
   * @param {string} dateString - The time string in HHMM format (e.g., "0730").
   * @returns {Date} A Date object with the hours and minutes set based on the input string.
   * @throws {Error} If the input is not a valid four-digit numeric string.
   */
  formatDateStringToDateObj = (dateString) => {
    if (!/^\d{4}$/.test(dateString)) {
      throw new Error('Invalid time string. Expected a four-digit numeric string (HHMM).');
    }

    const dateObj = new Date();

    const dateStringHours = parseInt(dateString.substring(0, 2), 10);
    const dateStringMinutes = parseInt(dateString.substring(2, 4), 10);

    dateObj.setHours(dateStringHours);
    dateObj.setMinutes(dateStringMinutes);
    dateObj.setSeconds(0);
    dateObj.setMilliseconds(0);

    return dateObj;
  };

  getInLineCutOffTime = () => {
    const cutOffTimeString = process.env.REACT_APP_GET_IN_LINE_CUTOFF_TIME || '1730';

    return this.formatDateStringToDateObj(cutOffTimeString);
  };

  getInLineStartTime = () => {
    const startTimeString = process.env.REACT_APP_GET_IN_LINE_START_TIME || '0700';

    return this.formatDateStringToDateObj(startTimeString);
  };

  /**
   * Retrieves a list of enabled location IDs from the environment variable `REACT_APP_GET_IN_LINE_ENABLED_LOCATIONS`.
   *
   * This function parses the environment variable string, which contains comma-separated location IDs,
   * trims any whitespace around each ID, and returns them as an array. If the environment variable is not set,
   * it returns an empty array.
   *
   * @returns {string[]} An array of enabled location IDs. Returns an empty array if no locations are specified.
   */
  enabledLocations = () => {
    return this.locationsString ? this.locationsString.split(',').map((loc) => loc.trim()) : [];
  };

  /**
   * Checks if the current provider is a "Get In Line" QR location.
   *
   * This function retrieves the current provider ID from the session storage and checks if it is included
   * in the list of enabled locations for the "Get In Line" feature.
   *
   * @returns {boolean} `true` if the current provider is a "Get In Line" QR location, `false` otherwise.
   */
  isGetInLineQRLocation = () => {
    const id = sessionStorage.getItem('currentProvider') || localStorage.getItem('currentProvider');
    return this.enabledLocations().includes(id);
  };

  /**
   * Formats the start time of the "Get In Line" feature for display.
   *
   * This function retrieves the start time using `getInLineStartTime()`,
   * formats it into a localized 12-hour time string, and returns it.
   * The format includes the hour and minute (e.g., "7:00 AM").
   *
   * @returns {string} A localized 12-hour time string representing the start time.
   */
  startTimeDisplay = () => {
    return this.getInLineStartTime().toLocaleTimeString([], {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    });
  };

  /**
   * Checks if the current time is between the start time and the cut-off time.
   *
   * @returns {boolean} `true` if the current time is between the start time and the cut-off time; otherwise, `false`.
   */
  isWithinOperatingHours = () => {
    const now = new Date();
    const startTime = this.getInLineStartTime();
    const cutOffTime = this.getInLineCutOffTime();

    return now >= startTime && now <= cutOffTime;
  };
}

export const getInLineUtil = new GetInLineUtil();
